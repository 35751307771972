

export const addToCart = (product) => {
    return{
        type: "ADD_TO_CART",
        
        payload: {
            id: product.id,
            title: product.title,
            price: product.price,
            qty: 1,
            stock: 100,
            category: 1,
            image: product.image,            
            subTotal: parseFloat(product.price)
        }
    }

}

export const increaseCart = (product) => {
    
    return{
        type: "INCREASE_CART",
        
        payload: {            
                id: product,
        }
    }

}

export const decreaseCart = (product) => {
    
    return{
        type: "DECREASE_CART",
        
        payload: {            
                id: product,
        }
    }

}

export const itemRemove = (product) => {
    
    return{
        type: "CART_ITEM_REMOVE",
        
        payload: {            
                id: product,
        }
    }

}

