import React from 'react';
import { connect } from 'react-redux';
import {  NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faMapMarked, faShoppingBasket, faUser, faHome } from '@fortawesome/free-solid-svg-icons';
import { addToCart, increaseCart, decreaseCart, itemRemove } from '../store/actions';

class Footer extends React.Component{


    componentDidMount(){
        
        this.scrollToBottom();
    }

    scrollToBottom = () => {
        

        let cartButton = document.getElementById("order_number");
            cartButton.className += " bounce"
        const checkFunction = setTimeout(() => {
            cartButton.className = "total_item order_number"  
          }, 500);
          
          return () => clearInterval(checkFunction);
        

    }
      
      componentDidUpdate() {
        this.scrollToBottom();
        
      }

   
    render() {
        return(
            <div className="footer fixed-bottom">
                <div className="col-12">
                    <div className="row">
                        <div className="col text-center mobile_bottom_menu">
                        <span className="btn d-sm-block d-xs-block d-md-none d-xxl-none d-lx-none" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                            <FontAwesomeIcon icon={faBars} />
                        </span>

                            
                        </div>
                        <div className="col text-center mobile_bottom_menu">
                            <FontAwesomeIcon icon={faUser} />
                        </div>
                        <div className="col text-center mobile_bottom_menu">
                            <NavLink to="/">
                                <FontAwesomeIcon icon={faHome} className="mobile_menu_home"/>
                            </NavLink>
                        </div>

                        <div className="col text-center mobile_bottom_menu">
                            <FontAwesomeIcon icon={faMapMarked} />
                        </div>
                        <div onClick={ this.props.func } className="col text-center mobile_bottom_menu" style={{ position: 'relative'}} ><FontAwesomeIcon icon={faShoppingBasket} />
                        { this.props.cartData.carts.length > 9 ?                            
                            <label id="order_number" className="total_item order_number" > 9+</label>
                            :
                            <label id="order_number" className="total_item order_number">{ this.props.cartData.carts.length } 

                            <div style={{ float:"left", clear: "both" }}
                                ref={(el) => { this.checkFunction = el; }}>
                            </div>
                            
                            </label>

                            
                        }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}




const mapStateToProps = (store) => {
    return {
        cartData: store.addToCart,
        
    }
}

const mapDispatchToProps = {
    addToCart,
    increaseCart,
    decreaseCart,
    itemRemove
    
}
export default connect(mapStateToProps, mapDispatchToProps)(Footer);