const initialState = {
    carts: []
  };
const addToCart = (state = initialState, action) => {
    
    
    switch (action.type){ 

        case "ADD_TO_CART":  
        const { payload } = action;
          
            return {
              ...state,
              carts: [...state.carts, payload  ]
              
            };

        case "INCREASE_CART":        
          
          let findIndex = state.carts.findIndex((cart) => cart.id === action.payload.id);  
          let dataEffect = state.carts.find((cart) => cart.id === action.payload.id);  
          
            state.carts[findIndex].qty = dataEffect.qty+=1;
            state.carts[findIndex].subTotal = (parseFloat(dataEffect.price) * parseFloat(dataEffect.qty));

            return {
              ...state,
              carts: [...state.carts]
            };

        case "DECREASE_CART":
        
          
          let findIndexDecrement = state.carts.findIndex((cart) => cart.id === action.payload.id);  
          let dataEffectDecrement = state.carts.find((cart) => cart.id === action.payload.id); 
          let removeData = state.carts.filter((cart) => cart.id !== action.payload.id); 
          

          
          if(dataEffectDecrement.qty > 1){
              state.carts[findIndexDecrement].qty = dataEffectDecrement.qty -=1;
              state.carts[findIndexDecrement].subTotal = (parseFloat(dataEffectDecrement.price) * parseFloat(dataEffectDecrement.qty));
              return {
                ...state,
                carts: [...state.carts]
              };
          }else{
            if(state.carts.length > 1){
              return {
                ...state,
                carts: removeData
              };
            }else{
              
              return {
                carts: []
              };
            }
            
          }

        case "CART_ITEM_REMOVE":

          let itemRemove = state.carts.filter((item) => item.id !== action.payload.id);
          if(state.carts.length > 1){
            return {
              ...state,
              carts: itemRemove
          } 
          }else{
            return {
              ...state,
              carts: []
          } 
          }
              
       
        default: return state;
    }

}

// const increaseCart = (state = initialState, action) => {

    
//   switch (action.type){ 

//       case "UPDATE_CART":
        
//         const findIndex = state.carts.findIndex((cart) => cart.id === action.payload.id)
        
//           return {
//             ...state,
//             carts: [...state.carts[findIndex].qty+1  ],
//           };
     
//       default: return state;
//   }

// }

export default (addToCart);