import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBasket, faShippingFast, faAngleUp, faAngleDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { addToCart, increaseCart, decreaseCart, itemRemove } from '../store/actions';

class CartList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addCartData: false,
            cartLenth: 0,
            
        } 
    }


    componentDidMount(){
        
        this.scrollToBottom();
    }

    scrollToBottom = () => {

        this.setState = {
            cartLenth: this.props.cartData.carts.length
        } 

        console.log(this.state);

        if(this.props.cartData.carts.length > 0){
            this.messagesEnd.scrollIntoView({ behavior: "smooth" });
        }

        let cartButton= document.getElementById("stickyHeader");
            cartButton.className += " bounce"
        const interval = setTimeout(() => {
            cartButton.className = "stickyHeader"  
          }, 500);
          
          return () => clearInterval(interval);
        

    }
      
      componentDidUpdate() {
        this.scrollToBottom();
      }


    render() {

        return (
            <div>
                <div id="stickyHeader" className={ 'stickyHeader' } onClick={ this.props.func }>
                    <div className="item_count">
                        <span className="total_basket"><FontAwesomeIcon icon={faShoppingBasket}/></span>
                        <span className="total_item">{ this.props.cartData.carts.length }</span> Items
                    </div>
                    <div className="item_amount">৳
                        { 
                            this.props.cartData.carts.reduce((cartTotal, item)  => {
                            return cartTotal + item.subTotal;
                            }, 0).toFixed(2) 
                        }

                    </div>
                </div>
                <div className={ `cartList ${ this.props.cartOpenClose || this.state.addCartData ? "cart_list_drawer_open" : "cart_list_drawer_close"} h-100` }>
                

                <div className="p-1" style={{ background: "#ddd" }}>
                    <div className="row">
                        <div className="col">
                            <h4><FontAwesomeIcon icon={faShoppingBasket} /> <span className="total_item">{ this.props.cartData.carts.length }</span> Items</h4>
                        </div>
                        <div className="col-3">
                            <button className="btn btn-default btn-sm" style={{ border: 'solid 1px #000' }} onClick={ this.props.func }>Close</button>
                        </div>
                    </div>
                </div>
                <div className="p-2" style={{ background: "#333", fontSize: "12px" }}>
                    <div className="row">
                        <div className="col text-white">
                            <p className="p-0 m-0">Shop ৳400 more and save ৳10 fee </p>
                        </div>
                        <div className="col-2 text-white">
                            <p className="p-0 m-0">৳19 </p>
                        </div>
                    </div>
                </div>

                <div className="p-2" style={{ background: "#ddd", fontWeight: "bold" }}>
                    <div className="row">
                        <div className="col text-blck">
                            <p className="p-0 m-0">
                                <FontAwesomeIcon icon={faShippingFast}/> Express Delivery
                            </p>
                        </div>
                    </div>
                </div>

               {
                   (this.props.cartData.carts.length > 0)?

                <div id="cart_add_list" className="cart_add_list" style={{ height: (window.innerHeight - 180) + 'px'}}>

                    { 
                        
                        this.props.cartData.carts.map((cart, index) => (

                            <div className="border-bottom" key={++index}>
                            
                                <div className="counter_arrow">
                                    <span className="product_increment" onClick={() => this.props.increaseCart(cart.id)} ><FontAwesomeIcon icon={faAngleUp}/></span> 
                                    <span className="item_number">{ cart.qty }</span>
                                    <span className="product_decrement" onClick={() => this.props.decreaseCart(cart.id)}><FontAwesomeIcon icon={faAngleDown}/></span>
                                </div>
                                <div className="cart_product_image">
                                    <img src={ cart.image } alt="" title="product Name" />
                                </div>
                                <div className="p-0 product_description">
                                    <div className="cart_product_title">{ cart.title }</div>
                                    <div className="cart_product_size">৳{ cart.price.toFixed(2) } / 180ml</div>
                                </div>
                                <div className="product_total_price">
                                    <div className="total_price_amount">৳ 
                                        { cart.subTotal.toFixed(2) } 
                                        <br/>
                                        <del className="text-danger">৳ { cart.subTotal.toFixed(2) }</del>
                                    </div>
                                </div>
                                <div className="total_price_remove" onClick={() => this.props.itemRemove(cart.id)}><FontAwesomeIcon icon={faTimes} /></div>
                                
                            </div>                        
                        )) 

                        
                    }

                    <div style={{ float:"left", clear: "both" }}
                        ref={(el) => { this.messagesEnd = el; }}>
                    </div>

                </div>
                  :
                  <div className={"cart_add_list" }  style={{ height: (window.innerHeight - 180) + 'px'}}>
                    <h1 className="text-center" style={ {color: '#ddd' }}>No Cart Found!</h1>
                  </div>

               }

                <div className="col-12">
                    <div className="row">
                        <div className="col-12 order_button">
                            <div className="bg-info w-100">
                                <NavLink to="/place_order">
                                    <span className="place_order">Order Place</span>
                                    <span className="ground_total">৳{ 
                                        this.props.cartData.carts.reduce((cartTotal, item)  => {
                                        return cartTotal +  item.subTotal;
                                        }, 0).toFixed(2) 
                                    }</span>
                                </NavLink>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </div>  
        );
    }
}


const mapStateToProps = (store) => {
    return {
        cartData: store.addToCart,
        
    }
}

const mapDispatchToProps = {
    addToCart,
    increaseCart,
    decreaseCart,
    itemRemove
    
}
export default connect(mapStateToProps, mapDispatchToProps)(CartList);