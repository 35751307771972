import React from 'react';
import Header from './layout/Header';
import { Switch, Route } from 'react-router-dom';
import CartList from './layout/CartList';
import Home from './Home';
import Details from './component/Details';
import Footer from './layout/Footer';



class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            drawer: false,
            carts: [],
            
        } 
    }


    openClose = () => {
        this.setState(prevState => ({ drawer: !prevState.drawer }));
    }
    

    render() {

 


        const { drawer } = this.state;

        return (
            <div>
                <Header />
                <CartList func = { this.openClose } cartOpenClose={drawer}/>
                    <div className="container-fluid pading_left">
                        <div className="row">
                            <Switch>
                                <Route exact path="/" component={Home}/>
                                <Route exact path="/details" component={Details} />
                            </Switch>
                        </div>
                    </div>
                    
                <Footer func = { this.openClose } cartOpenClose={drawer}/>
                
            </div>
        );
    }
   
}


export default App;
