import React from 'react';
class Sidebar extends React.Component {
    
    render() {
        return(
        <div>
            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" style={{width: "75%" }}>
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                   Mobile Menu
                </div>
            </div>

            <div className="d-sm-none d-none d-xl-block d-xxl-block fixed" style={{ width: "20%" }}>
                <div className="offcanvas-body">
                   Desktop Menu
                </div>
            </div>
        </div>
        ) 
    }
}

export default Sidebar;