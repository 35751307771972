import React from 'react';
import {  BrowserRouter } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store, persistor } from "./store";
import { Provider } from "react-redux"
import { PersistGate } from 'redux-persist/integration/react'

// store.subscribe(() => {
//   store.getState();
// })

ReactDOM.render(
  <BrowserRouter>
    <Provider store ={store} >
      <PersistGate loading={null} persistor={persistor}>
          <App/>
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);