import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle, faShoppingCart, faEye } from '@fortawesome/free-solid-svg-icons';
import { addToCart, increaseCart, decreaseCart } from './store/actions';

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            products:[],
            
        }
    }
    

    componentDidMount(){
        
        var _this = this;
        axios.get('https://fakestoreapi.com/products')
        .then(function (response) {
            _this.setState({products:response.data});
            console.log(response.data)
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
        .then(function () {
            // always executed
            console.log('done');
        });

    }


    checkQuantity(id){
        let findIndex = this.props.cartData.carts.findIndex(cart => cart.id === id )
        return this.props.cartData.carts[findIndex];
    }

    render() {
        // console.table(this.props.cartData.carts);
        return (
            <div className="Home">
                <div className="d-flex flex-wrap home_page_content  justify-content-center">
                <div className="product-text-center">
                    <div className="mb-2 product_wrapper">
                        <div className="image"> <img src="https://i.imgur.com/Jk44eEl.png" width="300" alt="product" /> </div>
                        <div className="about-product text-center">
                            <p >COX Headphone</p>
                            
                                
                                    <h4>$99.<small></small></h4> 

                        </div>
                        {/* Hover view */}
                            
                                
                            <div className="cart_view h-100 col-12">
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="price">
                                        <span>৳</span>
                                        <span className="price_amount">120.00</span>
                                    </h4>
                                </div>

                                <div className="col-12 quantity_section">
                                    <div className="row">
                                        <div className="col icon_cart_minus">
                                            <FontAwesomeIcon icon={faMinusCircle} />
                                        </div>
                                        <div className="col">
                                            <h3 className="quantity">1</h3>
                                        </div>
                                        <div className="col icon_cart_plus">
                                            <FontAwesomeIcon icon={faPlusCircle} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 text-white text_label">In Bag</div>
                            </div>
                            <div className="col-12">
                                <div className="bg-white details_view w-100" >
                                    <FontAwesomeIcon icon={faEye} /> Details 
                                </div>
                            </div>
                        </div>
                            
                        {/* Hover End */}
                    </div>
                    <button className="btn border-success add_to_cart w-100 mb-2 text-success" onClick={() => this.props.addToCart(2) }>
                        <FontAwesomeIcon icon={faShoppingCart} /> Add To Cart
                    </button>

                    <div className="col-12 mb-2 btn_background_color bg-success">
                        <div className="cart_minus quantity_common">-</div>
                        <div className="quantity_common quantity_show"><span>1111</span> In Bag</div>
                        <div className="cart_plus quantity_common">+</div>
                        <div style={{ clear: "both" }}></div>
                    </div>

                </div>
                {this.state.products.map((product, index) => (  
                        
                        <div className="product-text-center text-center" key={++index}>
                            <div className="mb-2 product_wrapper">
                                <div className="image"> <img src={product.image} alt={`hello`}/> </div>
                                <div className="about-product product-text-center text-center">
                                    <p className="title">{product.title}</p>
                                    <p>100 ml</p>
                                    <div style={{ width: '100%' }}>
                                        <h4>৳ {product.price} <del style={{ marginLeft: '7px', color: '#f00', marginTop: '5px', fontSize: '16px' }}> ৳ {product.price }</del></h4> 
                                        
                                    </div>
                                    
                                </div>
                                
                                {
                                    this.props.cartData.carts.find(cart => cart.id === product.id )  ? 
                                        <div className="cart_view h-100 col-12">
                                        
                                            <div className="row">
                                                <div className="col-12">
                                                    <h4 className="price">
                                                        <span>৳</span>
                                                        <span className="price_amount">{product.price}</span>
                                                    </h4>
                                                </div>
                                                
                                                <div className="col-12 quantity_section">
                                                    <div className="row">
                                                        <div className="col icon_cart_minus" onClick={() => this.props.decreaseCart(product.id)} >
                                                            <FontAwesomeIcon className="icon_quantity" icon={faMinusCircle}/>
                                                        </div>
                                                        <div className="col">
                                                        {   }
                                                            <h3 className="quantity">{ this.checkQuantity(product.id).qty }</h3>
                                                        </div>
                                                        <div className="col icon_cart_plus"  onClick={() => this.props.increaseCart(product.id)}>
                                                            <FontAwesomeIcon className="icon_quantity" icon={faPlusCircle} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 text-white text_label">In Basket</div>
                                            </div>
                                            <div className="col-12">
                                                <div className="bg-white details_view w-100">
                                                    <FontAwesomeIcon icon={faEye} /> Details 
                                                </div>
                                            </div>
                                        </div>
                                    : 
                                    <div className="cart_view h-100 col-12" onClick={() => this.props.addToCart(product)}>
                                        <h1 style={{ color: '#fff', marginTop: '45%' }}>Add To Cart</h1>
                                        <div className="col-12">
                                            <div className="bg-white details_view w-100">
                                                <FontAwesomeIcon icon={faEye} /> Details 
                                            </div>
                                        </div>
                                    </div>
                                }
                                   
                            
                            </div>

                            
                            {
                                this.props.cartData.carts.find(cart => cart.id === product.id )  ?                               
                                    <div className="col-12 mb-2 btn_background_color bg-success">
                                        <div className="cart_minus quantity_common" onClick={() => this.props.decreaseCart(product.id)}>-</div>
                                        <div className="quantity_common quantity_show">
                                        
                                        <span>{ this.checkQuantity(product.id).qty } </span> 
                                        In Bag</div>
                                        <div className="cart_plus quantity_common" onClick={() => this.props.increaseCart(product.id)} >+</div>
                                        <div style={{ clear: "both" }}></div>
                                    </div>
                                :
                                
                                <button className="btn border-success add_to_cart w-100 mb-2 text-success" onClick={() => this.props.addToCart(product)}>
                                        <FontAwesomeIcon icon={faShoppingCart}/> Add To Cart
                                </button>

                            }    
                               
                            
                        </div>
                    
                    
                ))}    

                             
                    
                </div>
            </div>
        );
    }
}


const mapStateToProps = (store) => {
    
    return {
        cartData: store.addToCart,
        
    }
}

const mapDispatchToProps = {
    addToCart,
    increaseCart,
    decreaseCart
}




export default connect(mapStateToProps, mapDispatchToProps)(Home);
