import React from 'react';
import axios from 'axios';
class Details extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            products:[],
        } 
    }

      

    render() {
        return <div>
             

                {this.state.products.map((product, index) => (  
                    <h1 key={++index}>{ ++index} </h1>
                    
                ))} 
            
        </div>
    }

   
    handleResize() {
        console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
    }
        
    componentDidMount(){
        console.log(window.innerHeight + 'px');

        window.addEventListener('resize', this.handleResize());
       
        

        var _this = this;
        axios.get('https://fakestoreapi.com/products')
        .then(function (response) {
            _this.setState({products:response.data});
            console.log(response.data)
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
        .then(function () {
            // always executed
            console.log('done');
        });
    }

    
}


export default Details;