import {  NavLink } from 'react-router-dom';
import Sidebar from './Sidebar';
import logo from '../common/beauty.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faMapMarked, faQuestion, faUser } from '@fortawesome/free-solid-svg-icons';



const  Header = () => {
    return <>
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top p-0">
        <div className="container-fluid nav_header p-0" style={{background:"#228b22"}}>
            <div className="col-sm-1 col-md-3">
                <div className="row">
                    <div className="col-3">
                        <FontAwesomeIcon className="d-sm-none d-none d-md-block d-xxl-block d-lx-block" icon={ faBars } style={{margin: "10px", fontSize: "35px" }}/>
                        <span className="btn d-sm-block d-xs-block d-md-none d-xxl-none d-lx-none" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                                <FontAwesomeIcon icon={ faBars } style={{ fontSize: "35px" }}/>
                        </span>
                    </div>
                    <div className="col-9 d-sm-none d-none d-md-block d-xxl-block d-lx-block">
                        <NavLink className="nav-link active" aria-current="page" exact activeClassName="current" to='/'>
                            <img src={logo} alt="Logo"/>
                        </NavLink>
                    </div>
                </div>
            </div>
            
            
            <div className="col-md-6 col-9 col-sm-11">
                <form className="d-flex">
                    <input className="form-control me-2 search" type="search" placeholder="Search" aria-label="Search"/>
                </form>
            </div>
            <div className="col-12 col-md-3 left_side_menu">
                <div className="row">
                    <div className="col-3 p-0 col-md-6 col-lg-3">
                        <NavLink className="location" to="/location"><FontAwesomeIcon icon={faMapMarked} /> Dhaka</NavLink>
                    </div>
                    <div className="col-4 p-0 d-md-none col-lg-4 d-lg-block"><NavLink className="help_more" to="help"><FontAwesomeIcon icon={faQuestion} /> Help & More</NavLink></div>
                    <div className="col-1 p-0 d-md-none d-lg-block"><NavLink className="language" to="/language"> EN </NavLink></div>
                    <div className="col-4 p-0 col-md-6 col-lg-4"><NavLink className="login_signup" to='/login'>
                            <FontAwesomeIcon icon={ faUser } /> Sign In 
                        </NavLink>
                    </div>
                </div>
            </div>
            
            {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                <NavLink className="nav-link active" aria-current="page" exact activeClassName="current" to='/'>Home</NavLink>
                </li>
                <li className="nav-item">
                <NavLink exact activeClassName="current" to='/details'>Details</NavLink>
                <a className="nav-link" to="/">Link</a>
                </li>
                <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" to="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Dropdown
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><a className="dropdown-item" to="/">Action</a></li>
                    <li><a className="dropdown-item" to="/">Another action</a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item" to="/">Something else here</a></li>
                </ul>
                </li>
            </ul>
            <form className="d-flex">
                <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                <button className="btn btn-outline-success" type="submit">Search</button>
            </form>
            </div> */}
        </div>
        
        </nav>
        <Sidebar/>
    </>

}

export default Header;